.login {
  height: 100vh;
  height: 100vh;
  background: linear-gradient(to bottom, #2b2b2b, #737272, #ffffff);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .bubble {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    opacity: 0.8;
    background: radial-gradient(circle, #f7f7f7, #a6a6a6);
    animation: bubble 4s ease-in-out infinite;
    bottom: 0;
    pointer-events: auto;

    &:nth-child(1) {
      top: 100%;
      left: 10%;
      animation-delay: 2s;
    }

    &:nth-child(2) {
      top: 80%;
      left: 30%;
      animation-delay: 2s;
    }

    &:nth-child(3) {
      top: 90%;
      left: 50%;
      animation-delay: 2s;
    }

    &:nth-child(4) {
      top: 100%;
      left: 70%;
      animation-delay: 3s;
    }

    &:nth-child(5) {
      top: 55%;
      left: 90%;
      animation-delay: 1s;
    }
  }

  .card {
    display: flex;
    width: 70%;
    background: linear-gradient(to bottom, #676666, #999898, #ffffff);
    border: 0.1px solid white;
    border-radius: 80px;

    .left {
      background-image: url("https://www.robotis.com/data/editor/2101/4d0c77837b56df271482318b631c6bb5_1610945033_14.jpg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 54%;
      padding: 30px;
      display: flex;
      flex-direction: column;
      border-top-left-radius: 80px;
      border-bottom-left-radius: 80px;
      align-items: center;
      color: white;

      .content {
        background-image: linear-gradient(
          to top,
          rgba(0, 0, 0, 0.286),
          rgba(128, 126, 126, 0),
          rgba(0, 0, 0, 0)
        );
        width: 85%;
        border-radius: 10px;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;

        h2 {
          font-size: 20px;
          font-weight: 700;
          margin-top: 100%;
          span {
            color: white;
          }
          color: #00ff2a;
        }

        .button {
          display: flex;
          justify-content: space-around;
          top: 20px;
          left: 20px;

          button {
            width: 200px;
            height: 40px;
            background-color: rgb(0, 132, 255);
            margin: 20px;
            color: white;
            position: relative;
            overflow: hidden;
            font-size: 14px;
            letter-spacing: 1px;
            font-weight: 500;
            text-transform: uppercase;
            transition: all 0.3s ease;
            cursor: pointer;
            border: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;

            &:before,
            &:after {
              content: "";
              position: absolute;
              width: 0;
              height: 2px;
              background-color: rgb(0, 132, 255);
              transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
            }

            &:before {
              right: 0;
              top: 0;
              transition: all 0.5s cubic-bezier(0.35, 0.1, 0.25, 1);
            }

            &:after {
              left: 0;
              bottom: 0;
            }

            span {
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              margin: 0;
              padding: 0;
              z-index: 1;

              &:before,
              &:after {
                content: "";
                position: absolute;
                width: 2px;
                height: 0;
                background-color: #44d8a4;
                transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
              }

              &:before {
                right: 0;
                top: 0;
                transition: all 0.5s cubic-bezier(0.35, 0.1, 0.25, 1);
              }

              &:after {
                left: 0;
                bottom: 0;
              }
            }

            p {
              padding: 0;
              margin: 0;
              transition: all 0.4s cubic-bezier(0.35, 0.1, 0.25, 1);
              position: absolute;
              width: 100%;
              height: 100%;

              &:before,
              &:after {
                position: absolute;
                width: 100%;
                transition: all 0.4s cubic-bezier(0.35, 0.1, 0.25, 1);
                z-index: 1;
                left: 0;
              }

              &:before {
                content: attr(data-title);
                top: 50%;
                transform: translateY(-50%);
              }

              &:after {
                content: attr(data-text);
                top: 150%;
                color: white;
              }
            }

            &:hover:before,
            &:hover:after {
              width: 100%;
            }

            &:hover span {
              z-index: 1;
            }

            &:hover span:before,
            &:hover span:after {
              height: 100%;
            }

            &:hover p:before {
              top: -50%;
              transform: rotate(5deg);
            }

            &:hover p:after {
              top: 50%;
              transform: translateY(-50%);
            }

            &.start {
              background-color: #44d8a4;
              box-shadow: 0px 5px 10px -10px rgba(0, 0, 0, 0.2);
              transition: all 0.2s ease;

              p:before {
                top: -50%;
                transform: rotate(5deg);
              }

              p:after {
                color: white;
                transition: all 0s ease;
                content: attr(data-start);
                top: 50%;
                transform: translateY(-50%);
                animation: start 0.3s ease;
                animation-fill-mode: forwards;
              }
            }

            &:hover:before,
            &.start:hover:after {
              display: none;
            }

            &:hover span,
            &.start:hover span {
              display: none;
            }

            &:active {
              outline: none;
              border: none;
            }

            &:focus {
              outline: 0;
            }
          }
        }

        @keyframes start {
          from {
            top: -50%;
          }
        }
      }
    }
    .right {
      display: flex;
      flex: 1;
      background-color: rgba(0, 0, 0, 0.35);
      border-top-right-radius: 80px;
      border-bottom-right-radius: 80px;
      .contentTwo {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        margin-top: 12%;
        height: 87%;
        justify-content: center;
        position: relative;
        .wrapper {
          width: 200px;
          height: 60px;
          position: relative;
          z-index: 1;

          .circle {
            width: 20px;
            height: 20px;
            position: absolute;
            border-radius: 50%;
            background-color: #fff;
            left: 15%;
            transform-origin: 50%;
            animation: circle7124 0.5s alternate infinite ease;

            &:nth-child(2) {
              left: 45%;
              animation-delay: 0.2s;
            }

            &:nth-child(3) {
              left: auto;
              right: 15%;
              animation-delay: 0.3s;
            }
          }

          .shadow {
            width: 20px;
            height: 4px;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.9);
            position: absolute;
            top: 62px;
            transform-origin: 50%;
            z-index: -1;
            left: 15%;
            filter: blur(1px);
            animation: shadow046 0.5s alternate infinite ease;

            &:nth-child(4) {
              left: 45%;
              animation-delay: 0.2s;
            }

            &:nth-child(5) {
              left: auto;
              right: 15%;
              animation-delay: 0.3s;
            }
          }
        }

        @keyframes circle7124 {
          0% {
            top: 60px;
            height: 5px;
            border-radius: 50px 50px 25px 25px;
            transform: scaleX(1.7);
          }

          40% {
            height: 20px;
            border-radius: 50%;
            transform: scaleX(1);
          }

          100% {
            top: 0%;
          }
        }

        @keyframes shadow046 {
          0% {
            transform: scaleX(1.5);
          }

          40% {
            transform: scaleX(1);
            opacity: 0.7;
          }

          100% {
            transform: scaleX(0.2);
            opacity: 0.4;
          }
        }

        .login-box {
          height: 85%;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 400px;
          padding: 30px;
          transform: translate(-50%, -50%);
          background-color: inherit;
          box-sizing: border-box;
          box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
          border-radius: 10px;
          h1 {
            margin-left: 40%;
            color: white;
          }
          p {
            text-align: center;
            color: rgba(255, 0, 0, 0.765);
            font-size: 25px;
          }
          .user-box {
            position: relative;
            margin: 70px 0;
            input {
              width: 100%;
              padding: 10px 0;
              font-size: 20px;
              color: black;
              margin-bottom: 30px;
              border: none;
              border-bottom: 1px solid #fff;
              outline: none;
              background: transparent;

              &:focus ~ label,
              :valid ~ label {
                top: -40px;
                color: black;
                font-size: 14px;
              }
            }

            label {
              position: absolute;
              top: -35px;
              left: 0;
              padding: 10px 0;
              font-size: 22px;
              color: #fff;
              pointer-events: none;
              transition: 0.5s;
            }
          }

          button {
            background-color: inherit;
            position: relative;
            display: inline-block;
            padding: 8px 30px;
            color: #ffffff;
            font-size: 16px;
            text-decoration: none;
            text-transform: uppercase;
            overflow: hidden;
            transition: 0.5s;
            margin-top: 40px;
            letter-spacing: 4px;
            border-radius: 10px;
            border: none;
            &:hover {
              cursor: pointer;
              background-color: rgb(0, 132, 255);
              color: #fff;
              border-radius: 5px;
              box-shadow: 0 0 4px #00d0ff, 0 0 10px #00d0ff, 0 0 50px #00d0ff,
                0 0 100px #00d0ff;
            }

            span {
              position: absolute;
              display: block;
            }
          }

          @keyframes btn-anim1 {
            0% {
              left: -100%;
            }
            50%,
            100% {
              left: 100%;
            }
          }

          span:nth-child(1) {
            bottom: 2px;
            left: -100%;
            width: 100%;
            height: 2px;
            background: linear-gradient(90deg, transparent, #03f40f);
            animation: btn-anim1 2s linear infinite;
          }
        }
      }
    }
  }
}
@keyframes bubble {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(0, -100vh);
    opacity: 0;
  }
}
