.leftbar {
  width: 253px;
  height: 90vh;
  .container {
    height: 100%;
    width: 100%;
    border-right: 2px solid black;
    box-shadow: 20px 10px 10px rgba(11, 11, 11, 0.1);
    .links {
      list-style-type: none;
      padding: 0;
      margin: 0;
      height: 50%;
      li {
        height: 22%;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #000000;
          text-decoration: none;
          transition: background-color 0.1s;
          font-size: 23px;
          height: 100%;
          align-items: center;
          align-items: center;
          text-align: center;
          justify-content: center;
          &:hover {
            background-color: gray;
          }
        }
      }
    }
  }
}
