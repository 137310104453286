.register {
  height: 100vh;
  height: 100vh;
  background: linear-gradient(to bottom, #2b2b2b, #737272, #ffffff);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .bubble {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    opacity: 0.8;
    background: radial-gradient(circle, #f7f7f7, #a6a6a6);
    animation: bubble 4s ease-in-out infinite;
    bottom: 0;
    pointer-events: auto;

    &:nth-child(1) {
      top: 100%;
      left: 10%;
      animation-delay: 2s;
    }

    &:nth-child(2) {
      top: 80%;
      left: 30%;
      animation-delay: 2s;
    }

    &:nth-child(3) {
      top: 90%;
      left: 50%;
      animation-delay: 2s;
    }

    &:nth-child(4) {
      top: 100%;
      left: 70%;
      animation-delay: 3s;
    }

    &:nth-child(5) {
      top: 55%;
      left: 90%;
      animation-delay: 1s;
    }
  }

  .card {
    display: flex;
    width: 70%;
    min-height: 800px;
    background: linear-gradient(to bottom, #676666, #9e9c9c, #ffffff);
    border: 0.1px solid white;
    border-radius: 80px;
    .left {
      order: 1;
      background-image: url("../../images/product.png");
      width: 40%;
      background-position: center bottom -7rem;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 50px;
      display: flex;
      flex-direction: column;
      border-top-right-radius: 80px;
      border-bottom-right-radius: 80px;
      align-items: center;
      color: white;

      .content {
        background-image: linear-gradient(
          to top,
          rgba(0, 0, 0, 0.8),
          rgba(0, 0, 0, 0.2)
        );
        min-height: 700px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 85%;
        border-radius: 10px;
        align-items: center;

        h1 {
          column-rule: white;
          font-size: 50px;
        }

        .button {
          display: flex;
          justify-content: space-around;
          top: 20px;
          left: 20px;

          h3 {
            font-size: 20px;
            font-weight: 700;
            color: rgb(0, 255, 89);
            justify-content: center;
            align-items: center;
            text-align: center;
          }
          button {
            width: 200px;
            height: 40px;
            background-color: rgb(0, 132, 255);
            margin: 20px;
            color: white;
            position: relative;
            overflow: hidden;
            font-size: 14px;
            letter-spacing: 1px;
            font-weight: 500;
            text-transform: uppercase;
            transition: all 0.3s ease;
            cursor: pointer;
            border: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            margin-left: 3rem;
            &:before,
            &:after {
              content: "";
              position: absolute;
              width: 0;
              height: 2px;
              background-color: rgb(0, 132, 255);
              transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
            }

            &:before {
              right: 0;
              top: 0;
              transition: all 0.5s cubic-bezier(0.35, 0.1, 0.25, 1);
            }

            &:after {
              left: 0;
              bottom: 0;
            }

            span {
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              margin: 0;
              padding: 0;
              z-index: 1;

              &:before,
              &:after {
                content: "";
                position: absolute;
                width: 2px;
                height: 0;
                background-color: #44d8a4;
                transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
              }

              &:before {
                right: 0;
                top: 0;
                transition: all 0.5s cubic-bezier(0.35, 0.1, 0.25, 1);
              }

              &:after {
                left: 0;
                bottom: 0;
              }
            }

            p {
              padding: 0;
              margin: 0;
              transition: all 0.4s cubic-bezier(0.35, 0.1, 0.25, 1);
              position: absolute;
              width: 100%;
              height: 100%;

              &:before,
              &:after {
                position: absolute;
                width: 100%;
                transition: all 0.4s cubic-bezier(0.35, 0.1, 0.25, 1);
                z-index: 1;
                left: 0;
              }

              &:before {
                content: attr(data-title);
                top: 50%;
                transform: translateY(-50%);
              }

              &:after {
                content: attr(data-text);
                top: 150%;
                color: white;
              }
            }

            &:hover:before,
            &:hover:after {
              width: 100%;
            }

            &:hover span {
              z-index: 1;
            }

            &:hover span:before,
            &:hover span:after {
              height: 100%;
            }

            &:hover p:before {
              top: -50%;
              transform: rotate(5deg);
            }

            &:hover p:after {
              top: 50%;
              transform: translateY(-50%);
            }

            &.start {
              background-color: #44d8a4;
              box-shadow: 0px 5px 10px -10px rgba(0, 0, 0, 0.2);
              transition: all 0.2s ease;

              p:before {
                top: -50%;
                transform: rotate(5deg);
              }

              p:after {
                color: white;
                transition: all 0s ease;
                content: attr(data-start);
                top: 50%;
                transform: translateY(-50%);
                animation: start 0.3s ease;
                animation-fill-mode: forwards;
              }
            }

            &:hover:before,
            &.start:hover:after {
              display: none;
            }

            &:hover span,
            &.start:hover span {
              display: none;
            }

            &:active {
              outline: none;
              border: none;
            }

            &:focus {
              outline: 0;
            }
          }
        }
      }
    }
    .right {
      display: flex;
      flex: 1;
      background-color: rgba(0, 0, 0, 0.35);
      border-top-left-radius: 80px;
      border-bottom-left-radius: 80px;
      .contentTwo {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        margin-top: 12%;
        height: 87%;
        justify-content: center;
        position: relative;
        .gearbox {
          height: 200px;
          width: 270px;
          position: relative;
          overflow: hidden;

          .overlay {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            background: transparent;
          }

          .gear {
            position: absolute;
            height: 60px;
            width: 60px;
            box-shadow: 0px -1px 0px 0px #888888, 0px 1px 0px 0px black;
            border-radius: 30px;

            &:after {
              content: "";
              position: absolute;
              height: 36px;
              width: 36px;
              border-radius: 36px;
              background: #111;
              top: 50%;
              left: 50%;
              margin-left: -18px;
              margin-top: -18px;
              z-index: 3;
              box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.1),
                inset 0px 0px 10px rgba(0, 0, 0, 0.1),
                inset 0px 2px 0px 0px #090909, inset 0px -1px 0px 0px #888888;
            }
          }

          .gear.large {
            height: 120px;
            width: 120px;
            border-radius: 60px;

            &:after {
              height: 96px;
              width: 96px;
              border-radius: 48px;
              margin-left: -48px;
              margin-top: -48px;
            }
          }

          .gear.one {
            top: 12px;
            left: 10px;

            .gear-inner {
              animation: counter-clockwise 3s infinite linear;
            }
          }

          .gear.two {
            top: 61px;
            left: 60px;

            .gear-inner {
              animation: clockwise 3s infinite linear;
            }
          }

          .gear.three {
            top: 110px;
            left: 10px;

            .gear-inner {
              animation: counter-clockwise 3s infinite linear;
            }
          }

          .gear.four {
            top: 13px;
            left: 128px;

            .gear-inner {
              animation: counter-clockwise 6s infinite linear;
            }
          }

          .gear-inner {
            position: relative;
            height: 100%;
            width: 100%;
            background: #555;
            border-radius: 30px;
            border: 1px solid rgba(255, 255, 255, 0.1);

            .bar {
              background: #555;
              height: 16px;
              width: 76px;
              position: absolute;
              left: 50%;
              margin-left: -38px;
              top: 50%;
              margin-top: -8px;
              border-radius: 2px;
              border-left: 1px solid rgba(255, 255, 255, 0.1);
              border-right: 1px solid rgba(255, 255, 255, 0.1);

              &:nth-child(2) {
                transform: rotate(60deg);
              }

              &:nth-child(3) {
                transform: rotate(120deg);
              }

              &:nth-child(4) {
                transform: rotate(90deg);
              }

              &:nth-child(5) {
                transform: rotate(30deg);
              }

              &:nth-child(6) {
                transform: rotate(150deg);
              }
            }
          }

          .large .gear-inner {
            border-radius: 60px;

            .bar {
              margin-left: -68px;
              width: 136px;
            }
          }
        }
        @keyframes clockwise {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }

        @keyframes counter-clockwise {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(-360deg);
          }
        }
        .login-box {
          height: 85%;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 400px;
          padding: 30px;
          transform: translate(-50%, -50%);
          background-color: inherit;
          box-sizing: border-box;
          box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
          border-radius: 10px;
          .rules {
            display: flex;
            width: 100%;
            h3 {
              color: rgb(255, 255, 255);
              width: 100%;
              top: -8rem;
              left: 0;
              position: absolute;
            }
          }
          h1 {
            margin-top: 0;
            color: white;
            text-align: center;
            font-size: 50px;
          }

          .user-box {
            position: relative;
            margin: 50px 0;
            input {
              width: 100%;
              padding: 10px 0;
              font-size: 20px;
              color: black;
              margin-bottom: 30px;
              border: none;
              border-bottom: 1px solid #fff;
              outline: none;
              background: transparent;

              &:focus ~ label,
              :valid ~ label {
                top: -40px;
                color: black;
                font-size: 14px;
              }
            }

            label {
              position: absolute;
              top: -35px;
              left: 0;
              padding: 10px 0;
              font-size: 22px;
              color: #fff;
              pointer-events: none;
              transition: 0.5s;
            }
          }

          button {
            background-color: inherit;
            position: relative;
            display: inline-block;
            padding: 8px 30px;
            color: #ffffff;
            font-size: 16px;
            text-decoration: none;
            text-transform: uppercase;
            overflow: hidden;
            transition: 0.5s;
            letter-spacing: 4px;
            border-radius: 10px;
            border: none;
            &:hover {
              cursor: pointer;
              background-color: rgb(0, 132, 255);
              color: #fff;
              border-radius: 5px;
              box-shadow: 0 0 4px #00d0ff, 0 0 10px #00d0ff, 0 0 50px #00d0ff,
                0 0 100px #00d0ff;
            }

            span {
              position: absolute;
              display: block;
            }
          }

          @keyframes btn-anim1 {
            0% {
              left: -100%;
            }
            50%,
            100% {
              left: 100%;
            }
          }

          span:nth-child(1) {
            bottom: 2px;
            left: -100%;
            width: 100%;
            height: 2px;
            background: linear-gradient(90deg, transparent, #03f40f);
            animation: btn-anim1 2s linear infinite;
          }
        }
      }
    }
  }
}
@keyframes bubble {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(0, -100vh);
    opacity: 0;
  }
}
